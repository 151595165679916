import React, { useState, useRef, useEffect } from "react";
import { Flex, Skeleton } from "@chakra-ui/react";

import all from "../images/portfolio/all.json";
import Lightbox from "react-awesome-lightbox";

import "react-awesome-lightbox/build/style.css";

import "../styles/our_portfolio.css";

import alladds from "../images/scraped/allportfolio.json";

function ImageSkeleton({ num }) {
  let all = [];

  for (let i = 0; i < num; i++) {
    all = [
      ...all,
      <Skeleton
        key={i}
        w="300px"
        h="300px"
        startColor="blue.500"
        endColor="orange.500"
        className="loaderskel"
      />,
    ];
  }

  return all;
}

export default function All() {
  const seeMoreButton = useRef();

  const [loading, setLoading] = useState(true);
  const [num, setNum] = useState(16);
  const [views, setViews] = useState([]);

  const [Open, setOpen] = useState(false);
  const [activeImg, setActiveImg] = useState("");

  const [visibleImages, setVisibleImages] = useState(12); // Show 12 images initially
  const [DeveloperMedia, setDeveloperMedia] = useState([]);
  const toggleNum = () => {
    setVisibleImages((prev) => prev + 12); // Load 12 more images each time button is clicked
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    setDeveloperMedia(array);
  };

  useEffect(() => {
    shuffleArray(alladds);
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex
        w={["100%", "100%", "100%", "100%", "1400px"]}
        mx="10px"
        my="20px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Flex
          className="picContainer"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? <ImageSkeleton num={num} /> : null}

          {DeveloperMedia.slice(0, visibleImages).map((img, index) => {
            return (
              <img
                key={index}
                src={img}
                alt="portfoliopic"
                onLoad={() => setLoading(false)}
                onClick={() => {
                  setActiveImg(img);
                  setOpen(true);
                }}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <button
          // className="businessEnquiryButton"
          className="button button--aylen"
          onClick={() => {
            toggleNum();
          }}
          ref={seeMoreButton}
        >
          <span className="biz">SEE MORE</span>
        </button>
      </Flex>
      {Open ? (
        <Lightbox
          image={activeImg}
          onClose={() => setOpen((prevState) => !prevState)}
        />
      ) : null}
    </Flex>
  );
}
